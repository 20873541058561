import React, { useContext, useEffect, useState } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";
import '../PasswordReset.css'
import ServerErrors from "./ServerError/ServerErrors";
import { Context } from "../../../index";
import { AuthService } from '../../../services/AuthService'
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";

type PasswordResetFormValues = {
    password: string;
}

const passwordResetSchema = yup.object().shape({
    password: yup.string().required('Введите пароль'),
});

const PasswordResetForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<PasswordResetFormValues>({ resolver: yupResolver(passwordResetSchema) });
    const [serverErrors, setServerErrors] = useState<String[]>([''])
    const navigate = useNavigate()
    const location = useLocation();
    const { store } = useContext(Context)
    useEffect(() => {
        console.log(location)
        console.log(location.pathname.split("/").at(-1))
    }, [])
    const handlerSubmit: SubmitHandler<PasswordResetFormValues> = async (data) => {
        console.log("Sending a password reset request")
        const recovery_token = location.pathname.split("/").at(-1)
        if (typeof recovery_token === "string" && recovery_token !== "password-reset") {
            AuthService.resetPassword(recovery_token, data.password).then((res) => {
                console.log('Password reset request sent. Result ' + res);
                navigate("/login");
            });
        } else {
            console.log("No token, nothing to recover.")
        }
    };
    return (
        <form className={"login__form"} onSubmit={handleSubmit(handlerSubmit)}>
            <div className={"login__form-field"}>
                <label>Пароль:</label>
                <input
                    type={"password"}
                    className={
                        errors?.password ? "login__form-password login__form-input login-error"
                            : "login__form-password login__form-input"
                    }
                    {...register("password", { required: 'Введите пароль' })}
                />
                {errors?.password && <p className={"login__form-error"}>{errors.password.message}</p>}
            </div>

            <div className={"login__form-props"}>
                <div className={"forgot-password"}>
                    <a href={"/login"}>Вспомнил</a>
                </div>
            </div>
            <ServerErrors errors={serverErrors} />
            <button className={store.isFetching ? "login__form-button button--loading" : "login__form-button"} type={"submit"}>
                <span className={"button__text"}>Сохранить</span>
            </button>
        </form>
    );
};

export default observer(PasswordResetForm);