import docImg from "../../../../assets/img/doc.png";
import '../Dashboard.css'
import IndividualReportsService from "../../../../services/IndividualReportsService";
import { ISummary } from "../Dashboard";
import { useEffect, useState } from "react";
const DashboardItem = (props: { item: ISummary, key: number, handleExternalEvent: Function }) => {
    const { item, handleExternalEvent } = props
    const handleDownload = (name: string) => {
        IndividualReportsService.download(name)
    }
    const approveReport = (name: string) => {
        IndividualReportsService.approveReport(name).then(() => {
            console.log('report approved');
            handleExternalEvent();
        })
    }
    const [isShowReportActionButtons, setIsShowReportActionButtons] = useState(true);
    const [nProfit, setNProfit] = useState("");
    const [periodProfit, setPeriodProfit] = useState("");

    useEffect(() => {
        setIsShowReportActionButtons(parseFloat(item.number_of_plays) > 0);
        setNProfit(Intl.NumberFormat(undefined, { style: 'currency', currency: 'RUB' }).format(parseFloat(item.profit)));
        setPeriodProfit(Intl.NumberFormat(undefined, { style: 'currency', currency: 'RUB' }).format(parseFloat(item.period_profit)));
    }
        , [])


    return (
        <div className={"dashboard__list-item"}>
            
            <div className={"list__item-doc__wrapper"}>
                {isShowReportActionButtons &&
                    <span onClick={() => handleDownload(item.key)} className={"list__item-doc"}>
                        <img src={docImg} alt="" />
                    </span>
                }
            </div>
           
            <p className={"list__item-quarter"}>{item.period}</p>
            
            <p className={"list__item-sum"}>Сумма: {periodProfit}</p>
            <p className={"list__item-total"}>Баланс личного счёта: {nProfit}</p>
            
            {isShowReportActionButtons &&
                <>
                    <div className={"list__item-approvement-btn__wrapper"}>
                        {item.paid_out ?
                            <button className={"list__item-approvement-btn approved"}>
                                <div>Выплачено</div>
                            </button> :
                            item.payout_requested ?
                                <button className={"list__item-approvement-btn approved"}>
                                    <div>Выплата запрошена</div>
                                </button> :
                                item.approved_by_individual ?
                                    <button className={"list__item-approvement-btn approved"}>
                                        <div>Согласован</div>
                                    </button> :
                                    <button onClick={() => approveReport(item.file_name)} className={"list__item-approvement-btn"}>
                                        <div className={"item-button__text"}>Согласовать</div>
                                    </button>
                        }
                    </div>
                    <div className={"list__item-download-btn__wrapper"}>
                        <button className={"list__item-download-btn"}
                            onClick={() => handleDownload(item.key)}
                        >Скачать</button>
                    </div>
                </>
            }
        </div>
    );
};

export default DashboardItem;