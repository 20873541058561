import React from 'react';
import '../../PasswordReset.css'
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';

interface IServerProps {
    errors: String[]
}

const ServerErrors: React.FC<IServerProps> = ({ errors }) => {
    return (
        <>
            {errors.length > 0 && errors[0] !== "" && <div className={"server__errors"}>
                {
                    errors.map((error, id) => <p>{error}</p>)
                }
                {/*<TransitionGroup className="errors-list">*/}
                {/*    {*/}
                {/*        errors.map((error, id) => <CSSTransition*/}
                {/*            key={id}*/}
                {/*            timeout={500}*/}
                {/*            classNames="item"*/}
                {/*        >*/}
                {/*            <p>{error}</p>*/}
                {/*        </CSSTransition>)*/}
                {/*    }*/}
                {/*</ TransitionGroup>*/}
            </div>}
        </>
    );
};

export default ServerErrors;