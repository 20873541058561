import React, {useContext, useEffect, useRef} from 'react';
import {Context} from "../../index";

const TelegramLogin = () => {
  const telegramWidgetRef = useRef(null);
  const {store} = useContext(Context)

  useEffect(() => {
    // Define the onTelegramAuth function
    window.onTelegramAuth = (user) => {
      store.telegramLogin(user);
    };

    const script = document.createElement('script');
    script.src = "https://telegram.org/js/telegram-widget.js?22";
    script.setAttribute('data-telegram-login', "ParadigmLKBot");
    script.setAttribute('data-size', "large");
    script.setAttribute('data-onauth', "onTelegramAuth(user)");
    script.setAttribute('data-request-access', "write");
    script.async = true;

    // Append the script to the ref element
    telegramWidgetRef.current.appendChild(script);
  }, []);

  return (
    <div>
      <div ref={telegramWidgetRef}></div>
    </div>
  );
};

export default TelegramLogin;
