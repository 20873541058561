import React, { useEffect, useState } from 'react';
import MoneyFlowService from "../../../services/MoneyFlowService";
import './MoneyFlow.css';
import {periodDecrypt} from "../../../helpers/periodDecrypt";

export interface IMoneyFlowItem {
  individual: string;
  artist: string;
  album: string;
  category: string;
  description: string;
  amount: string;
  is_taken_into_account_in_the_report: boolean;
  date_added: string;
  period: string;
  record_type: string;
  is_internal: boolean;
  upc: string;
}

const MoneyFlow = () => {
  const [moneyFlowItems, setMoneyFlowItems] = useState<IMoneyFlowItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchMoneyFlowItems = async () => {
      try {
        const data = await MoneyFlowService.getMoneyFlowItems();
        setMoneyFlowItems(data.money_flow_items || []);
      } catch (error) {
        console.error('Error fetching money flow items:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMoneyFlowItems();
  }, []);

  return (
    <div className="moneyflow__container">
      <div className="background-circle" />
      <h2 className="moneyflow__header">Движение средств</h2>
      {isLoading ? (
        <div className="lds-ring__container">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="moneyflow__list-wrapper">
          {moneyFlowItems.length > 0 ? (
            moneyFlowItems.map((item, index) => (
              <div key={index} className="moneyflow__list-item">
                <p className="list__item-date"><strong>Дата добавления:</strong> {new Date(item.date_added).toLocaleDateString()}</p>
                <p className="list__item-amount"><strong>Сумма:</strong> {Intl.NumberFormat(undefined, {
                  style: 'currency',
                  currency: 'RUB'
                }).format(parseFloat(item.amount))}</p>
                <p className="list__item-period"><strong>Период:</strong> {periodDecrypt(item.period)}</p>
                <p className="list__item-record-type"><strong>Тип записи:</strong> {item.record_type}</p>
              </div>
            ))
          ) : (
            <p className="no-reports">No money flow items available</p>
          )}
        </div>
      )}
    </div>
  );
};

export default MoneyFlow;
