import React, {Dispatch, SetStateAction} from 'react';
import './Popup.css'
const Popup = (props: {isActive: boolean, setActive: Dispatch<SetStateAction<boolean>>, children: React.ReactNode}) => {
    const {isActive, setActive, children} = props
    // onClick={e => e.stopPropagation()}
    return (
    <div className={isActive ? 'modal' + ' active' : 'modal' }>
        <div className={'modal_content'} onClick={e => e.stopPropagation()}>
            <div onClick={()=>setActive(false)} className={"cross-close"}></div>
            {children}
        </div>
    </div>
    );
};
// <div className={isActive ? 'modal' + 'active' : 'modal' } onClick={()=>{setActive(false)}}>
//     <div className={'modal_content'} onClick={e => e.stopPropagation()}>
//         {children}
//     </div>
// </div>
export default Popup;