import React from 'react';
import './Profile.css'
import {useForm, SubmitHandler} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";
import * as yup from "yup";


type ProfileFormValues = {
    email: string;
    tel: string;
    fio: string;
    bday_date: Date;
    credit_card: string;
    bank_name: string;
    tel_for_spb: string;
}


const profileSchema = yup.object().shape({
    email: yup.string().email('Не валидная почта').required('Введите почту'),
    tel: yup.string().required('Введите моб. телефон'),
    fio: yup.string().required('Введите ФИО'),
    bday_date: yup.date().required('Введите дату рождения'),
    credit_card: yup.string().required('Введите номер банковской карты'),
    bank_name: yup.string().required('Введите название банка, выпустившего карту'),
    tel_for_spb: yup.string().required('Введите номер тел. для перевода по СПБ')
});


const Profile = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<ProfileFormValues>({resolver: yupResolver(profileSchema)});
    const handlerSubmit: SubmitHandler<ProfileFormValues> = (data) => {
        console.log(data)
    };
    return (
        <div className={"profile__container"}>
            <div className={"background-circle"} />
            <div className={"profile__form-wrapper"}>
                <form className={"profile__form"} onSubmit={handleSubmit(handlerSubmit)}>
                    <div className={"profile__form-left"}>
                        <label className={"profile__form-label"}>Email <span>(логин)</span>:</label>
                        <input placeholder={"artist@email.com"} type="text" {...register("email")}
                            className={errors?.email && "error"}
                        />
                        <label className={"profile__form-label"}>ФИО:</label>
                        <input placeholder={"Артистов Игорь Петрович"} type="text" {...register("fio")}
                            className={errors?.fio && "error"}
                        />
                        <label className={"profile__form-label"}>Дата рождения:</label>
                        <input placeholder={"08.17.2022"} type="text" {...register("bday_date")}
                            className={errors?.bday_date && "error"}
                        />                                                                 
                    </div>
                    <div className={"profile__form-right"}>
                    <label className={"profile__form-label"}>Telegram:</label>
                        <input placeholder={"@telegram_id"} type="text"
                        />
                    <label className={"profile__form-label"}>Мобильный телефон:</label>
                        <input placeholder={"+79998887777"} type="text" {...register("tel")}
                            className={errors?.tel && "error"}
                        /> 
                        {/* <label className={"profile__form-label"}>Номер банковской карты <span>(для выплат роялти)</span>:</label>
                        <input placeholder={"7777 8888 9999 5555"} type="text" {...register("credit_card")}
                               className={errors?.credit_card && "error"}
                        />
                        <label className={"profile__form-label"}>Название банка, выпустившего карту:</label>
                        <input placeholder={"Мой Банк"} type="text" {...register("bank_name")}
                               className={errors?.bank_name && "error"}
                        /> */}
                        {/* <label className={"profile__form-label"}>Номер телефона для перевода по СБП:</label>
                        <input placeholder={"+7999888777"} type="text" {...register("tel_for_spb")}
                               className={errors?.tel_for_spb ? "last-child error" : "last-child"}
                        /> */}
                        
                        <button className={"profile__submit-btn"} type={"submit"}>Сохранить</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Profile;