import React from 'react';
import './PasswordReset.css'
import logo from '../../assets/img/logo-white.png'
import PasswordResetForm from "./PasswordResetForm/PasswordResetForm";
import { observer } from "mobx-react-lite";
const PasswordReset = () => {
    return (
        <div className={"login__container"}>
            <h1 className={"login__title"}><span>Роялти</span> кабинет</h1>
            <div className="login">
                <h1 className={"login__form-title"}>Сброс пароля</h1>
                <PasswordResetForm />
                <div className={"bg-circle"} />
            </div>
            <img src={logo} className={"login__logo"} />
        </div>
    );
};

export default observer(PasswordReset);