import React from 'react';
import './Login.css'
import logo from '../../assets/img/logo-white.png'
import LoginForm from "./LoginForm/LoginForm";
import {observer} from "mobx-react-lite";
import TelegramLogin from './TelegramLogin';
const Login = () => {
    return (
        <div className={"login__container"}>
            <h1 className={"login__title"}><span>Роялти</span> кабинет</h1>
            <div className="login">
                <div className={"login__form-title"}>ВХОД В РОЯЛТИ КАБИНЕТ</div>
                <LoginForm/>
                <br/>
                <TelegramLogin/>
                <div className={"bg-circle"}/>
            </div>
            {/* <div className='login__form-title'>Will be back soon!</div> */}
            <img src={logo} className={"login__logo"} />
        </div>
    );
};

export default observer(Login);