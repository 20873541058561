export const periodDecrypt = (period: string | undefined) => {
    if(!period) return
    if(period.split(/[^a-zа-яё0-9\s]/gi)
        .join('').replace(/\s+/g, ' ')
        .replace(' ', '-') === period) return period
    const normalizedPeriod = period
        .split(/[^a-zа-яё0-9\s]/gi)
        .join('').replace(/\s+/g, ' ')
        .replace(' ', '-')
    if(!normalizedPeriod.split('-')[1]) {
        const newPeriod =
            `${normalizedPeriod.split('Q')[1][0]}й Квартал ${normalizedPeriod.split('Q')[0]}`
        return newPeriod
    }
    else{
        const firstPart = normalizedPeriod?.split('-')[0]
        const secondPart = normalizedPeriod?.split('-')[1]
        if(firstPart.split('Q')[1][0] && secondPart.split('Q')[1][0] && firstPart.split('Q')[0]) {
            const newPeriod = `${firstPart?.split('Q')[1][0]}й-${secondPart?.split('Q')[1][0]}й  
           Квартал ${firstPart?.split('Q')[0]}`

            return newPeriod
        }
        return period
    }

}