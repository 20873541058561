
const API_URL = process.env.REACT_APP_BACKEND_API_URL;
export class AuthService {
    static async login(username: string, password: string): Promise<any> {
        try {
            const response = await fetch(`${API_URL}login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password })
            });
            console.log(response)
            return response.json()
        } catch (error) {
            console.log('error')
        }
    }

    static async verifyOtp(email: string, otp: string): Promise<any> {
        try {
            const response = await fetch(`${API_URL}verify-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username: email, otp })
            });
            return response.json();
        } catch (error) {
            console.error('Error verifying OTP', error);
        }
    }
    static async logout(): Promise<void> {
        localStorage.removeItem('user')
    }

    static async recoverPassword(username: string) {
        return fetch(`${API_URL}password-recovery`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify({ username })
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    static async resetPassword(token: string, password: string) {
        return fetch(`${API_URL}reset-password`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify({ token, password })
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    static async telegramLogin(telegramUser: any): Promise<any> {
        try {
            const response = await fetch(`${API_URL}telegram-login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(telegramUser)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}. Message: ${JSON.stringify(await response.json())}`);
            }
            return response.json();
        } catch (error) {
            console.error('Error during Telegram login:', error);
            throw error;
        }
    }
}