import React, { useContext, useEffect, useState } from 'react';
import './Cabinet.css'
import { Context } from "../../index";
import { Routes, Route, useNavigate } from "react-router-dom";
import logo from '../../assets/img/logo-white.png'
import Dashboard from "./Dashboard/Dashboard";
import NavBar from "./NavBar/NavBar";
import Profile from "./Profile/Profile";
import logout from "../../assets/img/logout (svg).svg";
import IndividualReportsService from "../../services/IndividualReportsService";
import MoneyFlowService from "../../services/MoneyFlowService";
import MoneyFlow from './MoneyFlow/MoneyFlow';
const Cabinet = () => {
    const { store } = useContext(Context);
    const { user } = store;
    const navigate = useNavigate();
    const [balance, setBalance] = useState<number>(0);
    useEffect(() => {
        if (!store.isAuth) navigate('/login')
    }, [store.isAuth])
    useEffect(() => {
        MoneyFlowService.getBalance().then(res => {
            setBalance(res['amount']);
        });
    }, [])
    return (
        <div className={"cabinet__container"}>
            <div className={"cabinet__top"}>
                <div className={"logo__wrapper"}>
                    <img className={"logo"} src={logo} alt="" />
                </div>
                <NavBar />
            </div>
            <div className={"cabinet__bottom"}>
                <div className={"cabinet__top-info"}>
                    <h1 className={"cabinet__title"}>
                        Роялти кабинет <span>{user.full_name}</span>
                    </h1>
                    <div className={"cabinet__balance"}>
                        <div className={"cabinet__balance-inner"}>
                            <div className={"balance_title"}>Баланс: </div>
                            <div>{Intl.NumberFormat(undefined, { style: 'currency', currency: 'RUB' }).format(balance)}</div>
                        </div>
                    </div>
                </div>
                <Routes>
                    <Route path={"individual-reports"} element={<Dashboard />} />
                    <Route path={"money-flow"} element={<MoneyFlow />} />
                    <Route path={"profile"} element={<Profile />} />
                </Routes>
            </div>
        </div>
    );
};

export default Cabinet;