import React, {useContext, useEffect, useState} from 'react';
import {useForm, Resolver, SubmitHandler} from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";
import '../PasswordRecovery.css'
import ServerErrors from "./ServerError/ServerErrors";
import {Context} from "../../../index";
import {AuthService} from '../../../services/AuthService'
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";

type PasswordRecoveryFormValues = {
    email: string;
}

const loginSchema = yup.object().shape({
    email: yup.string().email('Не валидная почта').required('Введите почту'),
});

const PasswordRecoveryForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<PasswordRecoveryFormValues>({resolver: yupResolver(loginSchema)});
    const [serverErrors, setServerErrors] = useState<String[]>([''])
    const navigate = useNavigate()
    const {store} = useContext(Context)
    // useEffect(() => {
    //     if(store.isAuth) {
    //         navigate('../cabinet/individual-reports')
    //     }
    // }, [store.isAuth])
    const handlerSubmit: SubmitHandler<PasswordRecoveryFormValues> = async (data) => {
        AuthService.recoverPassword(data.email).then((res) => {
            console.log('Password recovery request sent. Result ' + res);
            navigate("login");
        });
    };
    return (
        <form className={"login__form"} onSubmit={handleSubmit(handlerSubmit)}>
            <div className={"login__form-field"}>
                <label>Email:</label>
                <input
                    className={
                        errors?.email ? "login__form-email login__form-input login-error"
                            : "login__form-email login__form-input"
                    }
                       {...register("email", {required: 'Введите электронную почту'})}
                />
                {errors?.email && <p className={"login__form-error"}>{errors.email.message}</p>}
            </div>
            
            <div className={"login__form-props"}>
                <div className={"forgot-password"}>
                    <a href={"/login"}>Вспомнил</a>
                </div>
            </div>
            <ServerErrors errors={serverErrors} />
            <button className={store.isFetching ? "login__form-button button--loading" : "login__form-button"} type={"submit"}>
                <span className={"button__text"}>Сброс пароля</span>
            </button>
            <div className={"password-recovery-info"}>Введите email, указаный вами в лицензионном договоре, на этот адрес мы отправим вам ссылку для сброса пароля.</div>
        </form>
    );
};

export default observer(PasswordRecoveryForm);