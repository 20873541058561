interface AuthHeadersType {
    Authorization: string;
    'Content-Type': string
}
export const authHeaders = (): HeadersInit | undefined => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    if (user && user.accessToken) {
        // return { Authorization: 'Bearer ' + user.accessToken };  // for Spring Boot back-end
        // return {'x-access-token': user.accessToken};             // for Node.js Express back-end
        return {
            'Authorization': user.accessToken, // for the current Chalice backend
            'Content-Type': 'application/json'
        };
    } else {
        return undefined;
    }
}