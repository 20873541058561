import {authHeaders} from "../http/AuthHeaders";

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

class MoneyFlowService {
    static async getBalance() {
        try {
            const response = await fetch(`${API_URL}balance`, {
                method: 'GET', headers: authHeaders()
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
        }
    }

    static async getMoneyFlowItems() {
        try {
            const response = await fetch(`${API_URL}money-flow`, {
                method: 'GET', headers: authHeaders()
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
        }
    }
}

export default MoneyFlowService;