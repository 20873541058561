import { authHeaders } from "../http/AuthHeaders";
const API_URL = process.env.REACT_APP_BACKEND_API_URL;

type PayoutRequestFormValues = {
    fio: string;
    legal_status: string;
    inn: string;
    account_number: string;
    bank_bik: string;
    additional_info: string;
    payout_sum: string;
    phone: string;
    bank_name: string;
    country: string;
    entrepreneur: string;
}

class IndividualReportsService {
    static async getReports() {
        try {
            const response = await fetch(`${API_URL}individual-report`, {
                method: 'GET',
                headers: authHeaders()
            });
            const data = await response.json();
            return data;
        } catch (error) {
            throw error
        }
    }
    static async download(report_name: string) {
            return fetch(`${API_URL}download-individual-report`, {
                method: 'POST',
                headers:
                    Object.assign({}, authHeaders(), { 'Accept': 'application/octet-stream, binary/octet-stream' }),
                body: JSON.stringify({ report_name })
            })
            .then(res => res.blob())
            .then(blob => {
                var fileUrl = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = fileUrl;
                a.download = report_name.substring(report_name.indexOf('['));
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    static async approveReport(report_name: string) {
        return fetch(`${API_URL}approve-one-report`, {
            method: 'POST',
            headers:
                Object.assign({}, authHeaders(), { 'Accept': 'application/json' }),
            body: JSON.stringify({ report_name })
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    static async requestPayout(data: PayoutRequestFormValues) {
        return fetch(`${API_URL}request-payout`, {
            method: 'POST',
            headers: Object.assign({}, authHeaders(), { 'Content-Type': 'application/json' }),
            body: JSON.stringify(data)
        }).catch((error) => {
            console.error('Error:', error);
        });
    }
}

export default IndividualReportsService