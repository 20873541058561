import React, {useEffect, useState} from 'react';
import './Dashboard.css'
import {periodDecrypt} from "../../../helpers/periodDecrypt";
import {observer} from "mobx-react-lite";
import IndividualReportsService from "../../../services/IndividualReportsService";
import DashboardItem from "./DashboardItem/DashboardItem";
import Popup from "./Popup/Popup";
import PayoutRequest from "./PayoutRequest/PayoutRequest";
import MoneyFlowService from "../../../services/MoneyFlowService";

export interface ISummary {
  file_name: string;
  key: string;
  period: string | undefined;
  number_of_plays: string;
  profit: string;
  approved_by_individual: boolean;
  payout_requested: boolean;
  paid_out: boolean;
  period_profit: string;
}

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const [reports, setReports] = useState<ISummary[]>([])
  const [approvedReportsSum, setApprovedReportsSum] = useState<number>(0)
  const [toPayoutReportsSum, setToPayoutReportsSum] = useState<number>(0)
  const [triggerCounter, setTriggerCounter] = useState(0);

  const handleExternalEvent = () => {
    // increment counterRef to trigger the effect to run again
    setTriggerCounter(triggerCounter + 1);
  }

  const togglePayoutRequestPopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }

  useEffect(() => {
    IndividualReportsService.getReports().then(res => {
      MoneyFlowService.getBalance().then(bres => {
        let balance = bres['amount'];
        balance = balance < 0 ? 0 : balance;
        return balance;
      }).then(balance => {
        setReports(res);
        let sum: number = 0;
        let tprs: number = 0; // toPayoutReportsSum

        [...res].reverse().map((report: ISummary) => {
          // expected that items are in the descending period order that's we have reverse here to accommodate sorting on backend
          if (report.approved_by_individual && !report.payout_requested && !report.paid_out) {
            sum = parseFloat(report.profit);
          }
          if (!report.paid_out) {
            tprs = parseFloat(report.profit);
          }
        });

        setApprovedReportsSum(balance < sum ? balance : sum);
        tprs = tprs > 0 ? tprs : 0;
        setToPayoutReportsSum(balance < tprs ? balance : tprs);
        setIsLoading(false);
      });
    });
  }, [triggerCounter]);


  return (
    <div className={"dashboard__container"}>
      <div className={"background-circle"}/>

      <div className={"dashboard__top"}>
        <p className={"period-name"}>Отчётный период</p>
        <p className={"sum-name"}>Сумма отчёта за период</p>
        <p className={"balance-name"}>Баланс на конец отчетного периода</p>
      </div>


      {
        !isLoading ? <>
          {
            reports?.length <= 0 ? <div className={"no-reports"}>
              <div>

                <p className={"statement_dates-info"}>Самый первый отчёт появится через несколько месяцев после даты
                  релиза (через 3-4 месяца).<br/><br/>
                  Примерные сроки появления отчетов в Роялти Кабинете:<br/><br/>
                  1 квартал - конец мая / начало июня<br/>
                  2 квартал - конец августа / начало сентября<br/>
                  3 квартал - конец ноября / начало декабря<br/>
                  4 квартал - конец февраля / начало марта</p>
              </div>

            </div> : <div className={"dashboard__list"}>
              <div className={"dashboard__list-wrapper"}>
                {
                  reports?.map((item: ISummary, id) => {
                    const newPeriod: string | undefined = periodDecrypt(item.period)

                    const newNumberOfPlays = item.number_of_plays = item.number_of_plays.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                    const newItem: ISummary = {
                      ...item,
                      period: newPeriod,
                      profit: item.profit,
                      period_profit: item.period_profit,
                      number_of_plays: newNumberOfPlays
                    }
                    return <DashboardItem handleExternalEvent={handleExternalEvent} key={id} item={newItem}/>
                  })
                }
              </div>
              <div className={"page-bottom"}>
                <div>
                  <p className={"total__payment"}>ИТОГО Роялти к выплате: {Intl.NumberFormat(undefined, {
                    style: 'currency',
                    currency: 'RUB'
                  }).format(toPayoutReportsSum)}</p>
                  <button disabled={approvedReportsSum < 2985} onClick={() => setIsPopupOpen(true)}
                          className={"request-reward"}>Запросить выплату
                  </button>
                  <p className={"payout_button-info"}>Выплата роялти осуществляется при достижении баланса в 3 000 ₽</p>
                </div>
                <div>
                  <p className={"statement_dates-info"}>Примерные сроки появления отчетов в Роялти Кабинете:<br/><br/>
                    1 квартал - конец мая / начало июня<br/>
                    2 квартал - конец августа / начало сентября<br/>
                    3 квартал - конец ноября / начало декабря<br/>
                    4 квартал - конец февраля / начало марта</p>
                </div>
              </div>
            </div>

          }

        </> : <div className={"lds-ring__container"}>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      }
      <Popup isActive={isPopupOpen} setActive={setIsPopupOpen}><PayoutRequest isActive={isPopupOpen}
                                                                              payoutSum={approvedReportsSum}
                                                                              togglePRP={togglePayoutRequestPopup}
                                                                              handleExternalEvent={handleExternalEvent}/></Popup>
    </div>
  );
};

export default observer(Dashboard);