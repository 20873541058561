import React from 'react';
import './PasswordRecovery.css'
import logo from '../../assets/img/logo-white.png'
import PasswordRecoveryForm from "./PasswordRecoveryForm/PasswordRecoveryForm";
import {observer} from "mobx-react-lite";
const PasswordRecovery = () => {
    return (
        <div className={"login__container"}>
            <h1 className={"login__title"}><span>Роялти</span> кабинет</h1>
            <div className="login">
                    <h1 className={"login__form-title"}>Восстановление пароля</h1>
                <PasswordRecoveryForm />
                <div className={"bg-circle"} />
            </div>
            <img src={logo} className={"login__logo"} />
        </div>
    );
};

export default observer(PasswordRecovery);