import React, {useContext, useEffect, useState} from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Context } from "../../../index";
import ServerErrors from "./ServerError/ServerErrors";
import {toast} from "react-toastify";

type OtpFormValues = {
    otp: string;
}

const OtpForm: React.FC<{ email: string, onVerified: () => void }> = ({ email, onVerified }) => {
    const { register, handleSubmit, formState: { errors } } = useForm<OtpFormValues>();
    const [serverErrors, setServerErrors] = useState<string[]>([]);
    const { store } = useContext(Context);

    useEffect(()=> {
        toast("Введите код, отправленный на ваш email.");
    },[]);

    const handleOtpSubmit: SubmitHandler<OtpFormValues> = async (data) => {
        try {
            const res = await store.verifyOtp(email, data.otp);
            if (res?.error) {
                setServerErrors([res.error]);
            } else {
                onVerified(); // Callback to notify the parent component that verification is successful
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <form className={"login__form"} onSubmit={handleSubmit(handleOtpSubmit)}>
            <div className={"login__form-field"}>
                <label>Одноразовый код:</label>
                <input
                    type={"text"}
                    className={"login__form-otp login__form-input"}
                    {...register("otp", { required: 'Введите одноразовый код' })}
                />
                {errors?.otp && <p className={"login__form-error"}>{errors.otp.message}</p>}
            </div>
            <ServerErrors errors={serverErrors} />
            <button className={"login__form-button"} type={"submit"}>
                <span className={"button__text"}>Войти</span>
            </button>
        </form>
    );
};

export default OtpForm;
