import {IUser} from "../models/IUser";
import {makeAutoObservable} from "mobx";
import {AuthService} from "../services/AuthService";
import {toast} from 'react-toastify';

export default class Store {
    user = {} as IUser;
    isAuth = false;
    isFetching = false;

    // reports = [] as REPORTS NOT GLOBAL
    constructor() {
        makeAutoObservable(this)
    }

    setAuth(value: boolean) {
        this.isAuth = value
    }

    checkAuth() {
        if (localStorage.getItem('user')) {
            this.setUser(JSON.parse(localStorage.getItem('user') || '{}'))
            this.setAuth(true)
        }
    }

    setUser(user: IUser) {
        this.user = user
    }

    async login(email: string, password: string) {
        try {
            this.isFetching = true;
            const response = await AuthService.login(email, password);
            this.isFetching = false;
            if (response.message === "OTP sent to your email. Please verify to complete login.") {
                // Handle OTP request here
                // Possibly save some state indicating that the next step is OTP verification
                return { otpRequired: true, email };
            } else if (response.accessToken) {
                // Directly login the user if accessToken is present in the response
                localStorage.setItem('user', JSON.stringify(response));
                this.setAuth(true);
                this.setUser(response);
            } else {
                return { error: "Неверная почта или пароль" };
            }
        } catch (e) {
            console.log(e);
            this.isFetching = false;
            return { error: "An error occurred during the login process." };
        }
    }


     async verifyOtp(email: string, otp: string) {
        try {
            this.isFetching = true;
            const response = await AuthService.verifyOtp(email, otp);
            if (response.accessToken) {
                localStorage.setItem('user', JSON.stringify(response));
                this.setAuth(true);
                this.setUser(response);
            } else {
                this.isFetching = false;
                return {error: "Invalid OTP"};
            }
        } catch (e) {
            console.error(e);
            this.isFetching = false;
            return {error: "An error occurred during OTP verification"};
        }
    }

    logout() {
        AuthService.logout()
        this.setAuth(false)
        this.setUser({} as IUser)
    }

    async telegramLogin(user: any) {
        AuthService.telegramLogin(user)
            .then((response) => {
                console.log(response)
                localStorage.setItem('user', JSON.stringify(response))
                this.setAuth(true)
                this.setUser(response)
            })
            .catch((e) => {
                this.isFetching = false
                console.log(e)
                toast(String(e));
            })
    }
}