import React, { useContext, useState } from 'react';
import './NavBar.css'
import logout from '../../../assets/img/logout.svg'
import { NavLink } from "react-router-dom";
import { Context } from "../../../index";
const NavBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const { store } = useContext(Context)
    const navigation = [
        {to: 'individual-reports', title: 'Отчёты и Роялти'},
        // {to: 'money-flow', title: 'Движение средств'}
        // {to: 'add-realize', title: 'Добавить Релиз'},
        // {to: 'news', title: 'Новости Лейбла'},
        // {to: 'support', title: 'Поддержка'}
    ]
    return (
        <nav className={"cabinet__top-nav"}>
            <ul className={isMenuOpen ? "cabinet__top-list active" : "cabinet__top-list"}>
                <>
                    {
                        navigation?.map((navItem, id) => {
                            return <li key={id} className={"cabinet__top-list__item"}>
                                <NavLink onClick={() => setIsMenuOpen(false)} to={navItem.to} className={
                                    ({ isActive }) => (isActive ? 'active' : '')}>{navItem.title}</NavLink>
                            </li>
                        })
                    }
                    <li className={"cabinet__top-list__item profile"}>
                        {/* <NavLink to={"profile"} className={
                            ({isActive}) => (isActive ? 'cabinet__my-profile-list active' : 'cabinet__my-profile-list')
                        }>Мой Профиль</NavLink> */}
                        <img className={"logout"} onClick={() => store.logout()} src={logout} alt="" />
                    </li>
                </>
            </ul>
            <div className={isMenuOpen ? "menu-burger__btn active" : "menu-burger__btn"} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <span></span>
            </div>
        </nav>
    );
};

export default NavBar;
