import React, { useContext, useEffect, useState } from 'react';
import './PayoutRequest.css';
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import IndividualReportsService from "../../../../services/IndividualReportsService";
import { Context } from "../../../../index";
import infoicon from '../../../../assets/img/info.svg';
import doneicon from '../../../../assets/img/done_icon.svg';

type PayoutRequestFormValues = {
    fio: string;
    legal_status: string;
    inn: string;
    account_number: string;
    bank_bik: string;
    additional_info: string;
    payout_sum: string;
    phone: string;
    bank_name: string;
    country: string;
    entrepreneur: string;
}

const payoutRequestSchema = yup.object().shape({
    fio: yup.string().required('Введите ФИО'),
    legal_status: yup.string().required('Выберите правовой статус'),
    inn: yup.string().when('legal_status', {
        is: 'Самозанятый',
        then: yup.string().required('Введите ИНН'),
        otherwise: yup.string().notRequired(),
    }),
    account_number: yup.string().when('legal_status', {
        is: 'Самозанятый',
        then: yup.string().required('Введите номер счета'),
        otherwise: yup.string().notRequired(),
    }),
    bank_bik: yup.string().when('legal_status', {
        is: 'Самозанятый',
        then: yup.string().required('Введите БИК банка'),
        otherwise: yup.string().notRequired(),
    }),
    additional_info: yup.string(),
    phone: yup.string().when('legal_status', {
        is: 'Физическое лицо',
        then: yup.string().matches(/^\d+$/, 'Номер телефона должен состоять из чисел').required('Введите номер телефона'),
        otherwise: yup.string().notRequired(),
    }),
    bank_name: yup.string().when('legal_status', {
        is: 'Физическое лицо',
        then: yup.string().required('Введите название банка'),
        otherwise: yup.string().notRequired(),
    }),
    country: yup.string().when('legal_status', {
        is: 'Физическое лицо',
        then: yup.string().required('Введите название страны'),
        otherwise: yup.string().notRequired(),
    }),
    entrepreneur: yup.string().when('legal_status', {
        is: 'Индивидуальный Предприниматель',
        then: yup.string().required('Введите реквизиты предприятия'),
        otherwise: yup.string().notRequired(),
    }),
});

const PayoutRequest = (props: { 
    isActive: boolean, 
    payoutSum: number, 
    handleExternalEvent: Function, 
    togglePRP: Function // Добавлено для вызова togglePRP
}) => {
    const { isActive, payoutSum, handleExternalEvent, togglePRP } = props;
    const [formSubmitted, setFormSubmitted] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors }
    } = useForm<PayoutRequestFormValues>({ resolver: yupResolver(payoutRequestSchema) });

    useEffect(() => {
        reset();
    }, [isActive, reset]);

    const handlerSubmit: SubmitHandler<PayoutRequestFormValues> = (data) => {
        data.payout_sum = payoutSum.toString();
        console.log(data);
        IndividualReportsService.requestPayout(data).then((response) => {
            console.log('Payout request sent. Result ' + response);
            setFormSubmitted(true);
            handleExternalEvent();
        });
    };
    
    const { store } = useContext(Context);
    const { user } = store;
    const legalStatus = watch("legal_status", "Выберите правовой статус");

    const handleClosePopup = () => {
        togglePRP(); // Закрытие попапа
    };

    return (
        <div className={"payoutRequest__container"}>
            <div className={"background-circle"} />
            <div className={"payoutRequest__form-wrapper"}>
                {!formSubmitted ? (
                    <form className={"payoutRequest__form"} onSubmit={handleSubmit(handlerSubmit)}>
                        <p className="payoutRequest__form-heading">Запрос выплаты</p>
                        <ul className="payoutRequest__form-list">
                            <div className={"payoutRequest__form-left"}>
                                <li className="payoutRequest__form-item">
                                    <label className="payoutRequest__form-label" htmlFor="fio">Фамилия Имя Отчество</label>
                                    <input type="text" {...register("fio")} name="fio" placeholder="Введите ФИО" defaultValue={user.full_name} />
                                    {errors.fio && <p className="error-message">{errors.fio.message}</p>}
                                </li>
                                <li className="payoutRequest__form-item">
                                    <label className="payoutRequest__form-label" htmlFor="legal_status">Правовой статус</label>
                                    <select {...register("legal_status")} name="legal_status">
                                        <option value="">Выберите правовой статус</option>
                                        <option value="Самозанятый">Самозанятый</option>
                                        <option value="Индивидуальный Предприниматель">Индивидуальный Предприниматель</option>
                                        <option value="Физическое лицо">Физическое лицо</option>
                                    </select>
                                    {errors.legal_status && <p className="error-message">{errors.legal_status.message}</p>}
                                </li>
                                {legalStatus === "Самозанятый" && <li className="payoutRequest__form-item">
                                    <label className="payoutRequest__form-label" htmlFor="inn">ИНН Лицензиара</label>
                                    <input type="text" {...register("inn")} name="inn" placeholder="Введите ИНН" />
                                    {errors.inn && <p className="error-message">{errors.inn.message}</p>}
                                </li>}
                                {legalStatus === "Самозанятый" && <li className="payoutRequest__form-item">
                                    <label className="payoutRequest__form-label" htmlFor="account_number">Номер счета Лицензиара</label>
                                    <input type="text" {...register("account_number")} name="account_number" placeholder="Введите номер счета" />
                                    {errors.account_number && <p className="error-message">{errors.account_number.message}</p>}
                                </li>}
                                {legalStatus === "Физическое лицо" && <li className="payoutRequest__form-item">
                                    <label className="payoutRequest__form-label" htmlFor="phone">Номер телефона СБП</label>
                                    <input type="text" {...register("phone")} name="phone" placeholder="Введите номер телефона" />
                                    {errors.phone && <p className="error-message">{errors.phone.message}</p>}
                                </li>}
                                {legalStatus === "Физическое лицо" && <li className="payoutRequest__form-item">
                                    <label className="payoutRequest__form-label" htmlFor="bank_name">Название Банка</label>
                                    <input type="text" {...register("bank_name")} name="bank_name" placeholder="Напишите название Банка" />
                                    {errors.bank_name && <p className="error-message">{errors.bank_name.message}</p>}
                                </li>} 
                            </div>
                            <div className={"payoutRequest__form-right"}>
                                {legalStatus === "Самозанятый" && <li className="payoutRequest__form-item">
                                    <label className="payoutRequest__form-label" htmlFor="bank_bik">БИК Банка</label>
                                    <input type="text" {...register("bank_bik")} name="bank_bik" placeholder="Введите БИК Банка" />
                                    {errors.bank_bik && <p className="error-message">{errors.bank_bik.message}</p>}
                                </li>}
                                {legalStatus === "Физическое лицо" && <li className="payoutRequest__form-item">
                                    <label className="payoutRequest__form-label" htmlFor="country">Страна (Гражданство)</label>
                                    <input type="text" {...register("country")} name="country" placeholder="Введите название страны" />
                                    {errors.country && <p className="error-message">{errors.country.message}</p>}
                                </li>}
                                {(legalStatus === "Самозанятый" || legalStatus === "Физическое лицо") &&
                                <li className="payoutRequest__form-item">
                                    <label className="payoutRequest__form-label" htmlFor="additional_info">Дополнительная информация</label>
                                    <textarea {...register("additional_info")} name="additional_info" placeholder="Напишите дополнительную информацию" />
                                </li>}
                                {legalStatus === "Индивидуальный Предприниматель" && <li className="payoutRequest__form-item">
                                    <label className="payoutRequest__form-label" htmlFor="entrepreneur">Реквизиты ИП</label>
                                    <textarea {...register("entrepreneur")} name="entrepreneur" placeholder="Заполните реквизиты предприятия" />
                                    {errors.entrepreneur && <p className="error-message">{errors.entrepreneur.message}</p>}
                                </li>}
                            </div>
                            <div className="payoutRequest__form-info-wrapper-upper-upper">
                                <div className="payoutRequest__form-info-wrapper-upper">
                                    <img className="infoicon" src={infoicon} alt="info" />
                                    <p className="payoutRequest__form-info">Обратите внимание! Если вы выбираете статус «Физическое лицо», то вам нужно будет указать номер телефона и название банка.</p>
                                </div>
                            </div>
                            <button type="submit" className="payoutRequest__submit-btn">Отправить запрос</button>
                        </ul>
                    </form>
                ) : (
                    <div className="payoutRequestConfirmation">
                        <img className="doneicon" src={doneicon} alt="done"/>
                        <div><p className="payoutRequestConfirmation-text">Ваш запрос на выплату роялти успешно отправлен!</p></div>
                        <div className="payoutRequestConfirmation-textblock">
                            <p className="payoutRequestConfirmation-text2">Мы получили ваш запрос.</p>
                            <p className="payoutRequestConfirmation-text2">Пожалуйста, обратите внимание, что обработка заявок на выплату роялти и выплата роялти осуществляется в течение 10 рабочих дней.</p>
                            <p className="payoutRequestConfirmation-text2">Но мы постараемся сделать всё как можно быстрее.</p>
                        </div>
                        <button type="button" className="payoutRequest__submit-btn" onClick={handleClosePopup}>Хорошо</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PayoutRequest;
